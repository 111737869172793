<template>
  <main>
    <page-header-compact>
            Traslado de Vacunas
    </page-header-compact>
    <div class="container-fluid mt-n10">
        <div class="row">
            <div class="col-12">
                <div class="card shadow mb-3">
                    <div
                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
                    >
                        <h6 class="font-weight-bold card-title m-0">
                            <i class="fas fa-truck-loading"></i> Datos
                            Basicos
                        </h6>
                    </div>
                    <div class="card-body">
                        <div class="form-row">
                            <div class="form-group col-md-6">
                                <label class="form-label">
                                    Sede Origen
                                </label>
                                <VSelectSede v-model="traslado.sede_origen" :mode="'object'"  :class="{'is-error' : $v.traslado.sede_origen.$error}" ></VSelectSede>
                                <span v-if="!$v.traslado.sede_origen.required && $v.traslado.sede_origen.$dirty" class="text-danger">
                                    <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                                </span>
                            </div>
                            <div class="form-group col-md-6">
                                <label class="form-label">
                                    Sede Destino
                                </label>
                                <VSelectSede v-model="traslado.sede_destino" :mode="'object'"  :class="{'is-error' : $v.traslado.sede_destino.$error}" ></VSelectSede>
                                <span v-if="!$v.traslado.sede_destino.required && $v.traslado.sede_destino.$dirty" class="text-danger">
                                    <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                                </span>
                            </div>
                            <div class="col-4">
                                <label >Fecha <small class="text-danger">*</small></label>
                                <input v-model='traslado.fecha' class="form-control form-control-sm"
                                    :class="{'is-error' : $v.traslado.fecha.$error}"    type="date">
                                <span v-if="!$v.traslado.fecha.required && $v.traslado.fecha.$dirty" class="text-danger">
                                    <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card shadow mb-3">
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="font-weight-bold card-title m-0">
                            <i class="fas fa-truck-loading"></i>
                            Cargar
                        </h6>
                    </div>
                    <div class="card-body">
                        <div class="form-group row pt-0 pb-0 mb-1">
                            <div class="col-sm-12 col-xs-12 col-md-12 col-lg-12">
                                <label>Vacuna <small class="text-danger">*</small></label>
                                <VSelectVacunaBySede v-model="detalle.vacuna"  :id_sede_origen='traslado.sede_origen.ID'
                                                    :validator="$v.detalle.vacuna" mode="object">
                                </VSelectVacunaBySede>
                            </div>
                        </div>
                        <div class="form-group row pt-0 pb-0 mb-1">
                            <div class="col-4">
                                <label>Lote <small class="text-danger">*</small></label>
                                <select  v-model='detalle.lote' class="form-control form-control-sm" v-on:click="cant_by_lote">
                                    <option v-for="(inventario , index) in inventario"
                                            :key="index" v-bind:value="inventario.lote">
                                        {{ inventario.lote }}
                                    </option>
                                </select>
                            </div>
                            <div class="col-4">
                                <label >Existencia</label>
                                <input v-model='existencia' class="form-control form-control-sm input-group-solid " name="precio"
                                        readonly
                                        step=".01" type="number">
                            </div>
                            <div class="col-2">
                                <label for="cantidad">Cantidad <small class="text-danger">*</small></label>
                                <input id="cantidad" v-model='detalle.cantidad' class="form-control form-control-sm"
                                        name="cantidad" placeholder="Cantidad"
                                        type="number">
                            </div>
                            <div class="col-2">
                                <label>&nbsp;</label>
                                <button class="btn btn-primary btn-block btn-sm shadow-sm"
                                        v-on:click.prevent='addVacuna'>+
                                </button>
                            </div>
                        </div>
                        <div v-if='errors.add_vacuna.length' class="mt-5 row">
                            <div class="col-12">
                                <div class="alert alert-danger">
                                    <h6><strong>Se han encontrado los siguientes errores:</strong></h6>
                                    <ul v-for='(error, index) in errors.add_vacuna' :key="index">
                                        <li>{{ error }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card shadow">
                    <div
                        class="card-header py-3 d-flex flex-row align-items-center justify-content-between"
                    >
                        <h6 class="font-weight-bold card-title m-0">
                            <i class="fas fa-list"></i> Detalles
                        </h6>
                    </div>
                    <div class="card-body table-responsive">
                        <table class="table table-sm" style="font-size: 14px">
                            <thead>
                            <tr>
                                <th>Vacuna</th>
                                <th>Fecha vencimiento</th>
                                <th>Lote</th>
                                <th>Cantidad</th>
                                <th colspan="2"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(detalle, index) in traslado.detalles" :key="index">
                               <td>{{ detalle.nombre }}</td>
                                <td>{{ detalle.fecha_vencimiento }}</td>
                                <td>{{ detalle.lote }}</td>
                                <td>
                                    <input v-model='detalle.cantidad' class="form-control form-control-sm"
                                         step=".01" style="width: 100%"
                                        type="number"
                                        @input='checkStock(index)'>
                                </td>
                                <td>
                                    <button
                                        class="btn btn-danger btn-sm shadow-sm"
                                        title="Eliminar"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        @click="removerItem(index)"
                                    >
                                        <i class="fas fa-trash text-white-50 fa-fw"></i>
                                    </button>
                                </td>
                                <td></td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <button class="btn btn-success shadow-sm mt-2" @click="guardar();" >
                            <i class="fas fa-save text-white-50 fa-fw"></i> Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </main>
</template>
<script>
import Swal from 'sweetalert2';
import {required} from "vuelidate/lib/validators";
import VSelectSede from '../../../components/common/VSelectSede';
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import VSelectVacunaBySede from "../../../components/common/VSelectVacunaBySede";
import {isEmpty} from "lodash";
import trasladoVacunaService from '../../../services/trasladoVacunaService';
export default {
    components: {
        VSelectSede,PageHeaderCompact,VSelectVacunaBySede
    },
    data() {
        return {
           existencia:'',
            items: [],
            detalle: {
                vacuna : {},
                id: "",
                id_vacuna:'',
                nombre: "",
                lote: "",
                fecha_vencimiento: "",
                cantidad: "",
                stock_disponible : 0,
            },
            traslado:{
                sede_origen: {},
                sede_destino: {},
                fecha:'',
                detalles:[]
            },
             errors: {
                add_vacuna: [],
                ending: [],
            }
        }
    },
    validations (){
        return {
            traslado:{
                sede_origen:{required},
                sede_destino:{required},
                fecha:{required},
            },
            detalle : {
                vacuna : {required},
                cantidad : {required}
            },
        }
    },
    methods: {
        addVacuna: function() {
            console.log("hola");
            this.$v.detalle.$touch();
            if (this.$v.detalle.$invalid) return;

            var _valid = true;

            this.errors.add_vacuna = [];

            if (parseFloat(this.detalle.cantidad) <= 0) {
                this.errors.add_vacuna.push('la cantidad no puede ser menor o igual a 0');
                _valid = false;
            }

            if (parseFloat(this.detalle.cantidad) > parseFloat(this.existencia[0])) {
                this.errors.add_vacuna.push('la cantidad no puede ser mayor a la cantidad disponible en stock, unidades en stock [' + this.existencia + ']');
                _valid = false;
            }

            if (_valid) {

                this.detalle.id_vacuna = this.detalle.vacuna.id;
                this.detalle.id = this.detalle.vacuna.id;
                this.detalle.nombre = this.detalle.vacuna.text;
                //this.traslado.sede_origen = this.sede_origen.ID;
                this.detalle.fecha_vencimiento = this.detalle.vacuna.inventario.find(x => String(x.lote) === String(this.detalle.lote)).fecha_vencimiento;
                this.detalle.stock_disponible = this.existencia[0];

                this.traslado.detalles.push({...this.detalle });
                this.detalle.vacuna={};
            }
        },
        checkStock: function(index) {

            this.errors.add_vacuna = [];

            const stock_disponible = this.traslado.detalles[index].stock_disponible;

            const vac = this.traslado.detalles[index];

            const total_agregado = this.traslado.detalles.filter(x => parseInt(x.id) === parseInt(vac.id))
                .reduce((t,c) => {
                    return Number(t) + Number(c.cantidad)
                }, 0)

            if (parseFloat(stock_disponible) < parseFloat(total_agregado)) {
                this.errors.add_vacuna.push('La cantidad facturada no puede exceder la cantidad en stock');
            }

        },
        async guardar() {

            try {
                this.$v.traslado.$touch();

                if(this.$v.traslado.$invalid) return;

                this.LoaderSpinnerShow();
                await trasladoVacunaService.store(this.traslado);
                this.LoaderSpinnerHide();

                Swal.fire("Exito","Datos guardados con exito","success");
                this.limpiarCampos();
            } catch (error) {
                console.log(error);
                this.LoaderSpinnerHide();

                Swal.fire("Ups!","ha ocurrido un error al procesar la solicitud","error");
            }
        },
        removerItem(index){
            this.traslado.detalles.splice(index, 1);
        },
        limpiarCampos(){
            this.traslado.sede_origen={};
            this.traslado.sede_destino={};
            this.traslado.fecha='';
            this.traslado.detalles=[];
            this.detalle.vacuna={};
            this.existencia='';
        },
        async cant_by_lote() {

            let lote=this.detalle.lote;
            const response = await trasladoVacunaService.cant_by_lote({
                id_sede:this.traslado.sede_origen.ID,
                lote:lote,
                id_vacuna:this.detalle.vacuna.id
            });

           console.log(response.data);
            this.existencia=response.data.map(x=>{
                return x.stock;
            });

        }


    },
    computed: {
        inventario: function() {

            if (isEmpty(this.detalle.vacuna)) {
                return [];
            }

            let result = [];

            let cantidad_por_lote = this.detalle.vacuna.inventario.map(x => {
                return {
                    'lote': x.lote,
                    'cantidad': Number(x.cantidad),
                    'fecha_vencimiento': x.fecha_vencimiento
                }
            });

            cantidad_por_lote.reduce(function(summed, value) {
                if (!summed[value.lote]) {
                    summed[value.lote] = { 'lote': value.lote, 'cantidad': 0 };
                    result.push(summed[value.lote]);
                }

                summed[value.lote].cantidad += value.cantidad;
                return summed;
            }, {});

            return result;
        },
        /* cantidad_por_lote: function() {

            if (isEmpty(this.inventario)) {
                return 0;
            }

            if (this.detalle.lote === '') {
                return 0;
            }

            return this.inventario.find(x => x.lote === this.detalle.lote) ? this.inventario.find(x => x.lote === this.detalle.lote).cantidad : 0;
        }, */
    }
}

</script>
